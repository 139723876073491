.btn-container-login {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0.3rem 0 0;
}

.btn-container-login button {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  /* Background */
  border-radius: 0;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  outline: 1px solid #a78a48;
  outline-offset: 3px;
  width: 170px;
  height: 44px;
  border: none;
  /* TKE Gold */
  box-shadow: none;
  background: #a78a48;
  /*  */
}
.btn-container-login button:hover {
  background: #ae8947;
  outline: none;
  box-shadow: none;
  outline: 1px solid #a78a48;
  outline-offset: 5px;
}
.btn-container-login button:focus {
  background: #ae8947;
  outline: none;
  border-color: #ae8947;
  border: none;
  box-shadow: none;
}

.btn-container-login button:active {
  background: #ae8947;
  outline: none;
  border-color: #ae8947;
  border: none;
  box-shadow: none;
}

/* End Of BTN */
/*  */
/*  */

.login-input-wrapper input {
  width: 502px;
  height: 50px;
  left: 469px;
  top: 382px;

  background: #ffffff;
}

.login-input-wrapper input:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.login-input-wrapper input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #828282;
}

/* login-forgot */
.login-forgot {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  cursor: pointer;
  color: #000000;
}

.login-forgot:hover {
  color: #4319c0;
}

/*  */
@media only screen and (max-width: 750px) {
  .login-input-wrapper input {
    width: 500px;
  }
}
@media only screen and (max-width: 600px) {
  .login-input-wrapper input {
    width: 341px;
  }
  .login-forgot {
    font-size: 13px;
  }
  .login-container {
    width: 100%;
  }
  .login-input-wrapper input {
    width: 100%;
  }

  .btn-container-login button {
    font-size: 15px;
    line-height: 20px;
    padding: 2px;
    width: 130px;
    height: 35px;
  }
}
@media only screen and (max-width: 400px) {
  .login-input-wrapper input {
    /* width: 300px; */
  }
}
