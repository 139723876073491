.body-wrapper {
  width: 52.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.travel-questionaire {
  background: hsla(0, 0%, 100%, 0.7);
  min-height: 25.7rem;
  /* background: #ffffffce; */
  /* opacity: 0.8; */
  padding: 1rem;
  display: flex;
  justify-content: center;
  padding: 2rem 6rem;
}

/*  */
@media only screen and (max-width: 640px) {
  .travel-questionaire {
    padding: 2rem 5rem;
  }
}
@media only screen and (max-width: 600px) {
  .travel-questionaire {
    padding: 1.8rem 4rem;
    min-height: 20.7rem;
  }
}
@media only screen and (max-width: 575px) {
  .body-wrapper {
    width: 35rem;
  }
}
@media only screen and (max-width: 559px) {
  .body-wrapper {
    width: 30rem;
  }
  .travel-questionaire {
    padding: 1.8rem 3rem;
  }
}
@media only screen and (max-width: 510px) {
  .travel-questionaire {
    padding: 1.5rem 2rem;
  }
}
@media only screen and (max-width: 485px) {
  .travel-questionaire {
    padding: 1.5rem 1rem;
  }
}
@media only screen and (max-width: 479px) {
  .body-wrapper {
    width: 25rem;
  }
}
@media only screen and (max-width: 455px) {
  .body-wrapper {
    width: 100%;
  }
  .travel-questionaire {
    padding: 0.5rem 0.3rem;
    width: 100%;
  }
}
@media only screen and (max-width: 380px) {
  .body-wrapper {
    width: 100%;
  }
}
/* @media only screen and (max-width: 380px) {
  .body-wrapper {
    width: 22rem;
  }
}
@media only screen and (max-width: 330px) {
  .body-wrapper {
    width: 18rem;
  } */
