.footer-container {
  text-align: center;
  /* width: 1254px; */
  border-top: 1px solid #3e3e3e;
  padding: 1rem;
  margin: 1rem auto;
  margin-top: 6rem;
  /* margin-bottom: 11rem; */
}
.footer-container span {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 1rem 0;
}

@media only screen and (max-width: 575px) {
  .footer-container {
    width: 459px;
    padding: 1rem;
    margin: 0rem auto;
    margin-top: 4rem;
  }

  .footer-container span {
    padding: 0.4rem 0;
    font-size: 12px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-container {
    width: 388px;
  }
}
@media only screen and (max-width: 410px) {
  .footer-container {
    width: 350px;
  }
}
@media only screen and (max-width: 390px) {
  .footer-container {
    width: 290px;
  }

  .footer-container {
    width: 290px;
  }
}
