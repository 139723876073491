.itenary-container {
  color: #000000;
  /* padding: 2rem; */
}
.itenary-container .h22 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.itenary__container-1 h1 {
  font-weight: 600;
  font-size: 24px;
}
.itenary__container-1 h2 {
  font-weight: 600;
  font-size: 20px;
}
.itenary__container-1 h3 {
  font-weight: 500;
  font-size: 18px;
}
.itenary__container-1 h4 {
  font-weight: 500;
  font-size: 16px;
}
.itenary__container-1 ul,
.itenary__container-1 ol {
  font-weight: 400;
  font-size: 17px;
}

.itenary__container-1 span {
  font-weight: 300;
  /* font-size: 12px; */
  line-height: 15px;
}

.itenary__container-1 a {
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  /* color: blue; */
  cursor: pointer;
}
.itenary__container-1 p {
  font-weight: 400;
  font-size: 14px;
  /* line-height: 15px; */
  letter-spacing: 0.02em;
  padding: 0;
  margin: 3px;
}

@media only screen and (max-width: 450px) {
  .itenary-container .h22 {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
  }

  .itenary__container-1 p {
    font-size: 12px;
  }
}
