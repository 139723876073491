/* .errorMsg {
  color: orangered;
  font-size: 0.8rem;
  font-style: italic;
  margin: 1rem auto;
  border: 1px dashed gray;
  padding: 0.3rem;
} */

.errorMsgWrapper {
  color: #e60023;
  background-color: #fce6e8 !important;
  padding: 10px 25px;
  margin-bottom: 30px;
  margin-top: -20px;
  border-radius: 3px;
}
.errorMsg {
  color: #e60023;
  font-size: 0.8rem;
  padding: 8px 0;
}

.color1 {
  background-color: #fce6e8 !important;
}
