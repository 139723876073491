/* user-trip-list */

.user-trip-list {
  padding: 2rem 0;
  margin: 2rem 0;
}

.trip-head {
}

.trip-list {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
}

.rounded-before {
  width: 29px;
  height: 29px;
  /* background: #d9d9d9; */
  background: rgb(167, 138, 72);
  border-radius: 100%;
  position: relative;
}

.rounded-line {
  /* content: ""; */
  /* font-size: 20px; */
  position: absolute;
  width: 91.01px;
  height: 0px;
  right: -2rem;
  top: 70px;
  border: 2px solid rgba(167, 138, 72, 0.7);
  transform: rotate(-90deg);
}

.trip-content {
  text-decoration: none;
  padding: 0 2rem;
  width: 55.7rem;
  height: 6rem;
  background: #ffffff;
  border: 1px solid rgba(167, 138, 72, 0.3);
  border-radius: 10px;
  justify-content: space-between;
  /* gap: 6rem; */
}

.backGround {
  background: #d8d8d8;
  border: 1px solid rgba(0, 0, 0, 0.9);
}

.trip-head {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.trip-logo-name {
  display: flex;
  align-items: center;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 1rem;
}
.trip-logo-name img {
  width: 59px;
  height: 59px;
  /* background: url(pexels-jimmy-teoh-1010657); */
  border-radius: 100px;
}
.trip-logo-name span {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  color: #000000;
}

.trip-start-end {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.trip-start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.span-one {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #000000;
}
.span-two {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #000000;
}

.trip-end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.trip-arrow {
  color: #000000;
}

.up-coming-view {
  background: #a78a48;
  padding: 0rem 0.2rem;
}
.up-coming-view span {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}

/* END OF user-trip-lis */
/*  */

/*  */

@media only screen and (max-width: 767px) {
  .trip-content {
    width: 30rem;
  }
  .trip-content {
    padding: 0 1rem;
  }
  .trip-logo-name img {
    width: 45px;
    height: 45px;
  }

  .trip-logo-name span {
    font-size: 14px;
    line-height: 16px;
  }
  .trip-logo-name {
    gap: 0.5rem;
  }
  .up-coming-view {
    padding: 0.1rem 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .trip-start-end {
    gap: 1.5rem;
  }

  .span-one {
    font-size: 13px;
    line-height: 8px;
  }

  .span-two {
    font-size: 14px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .trip-content {
    padding: 0 0.4rem;
  }
  .trip-list {
    gap: 35px;
  }

  .span-one {
    line-height: 4px;
  }

  .span-two {
    font-size: 13px;
  }

  .trip-start {
    align-items: flex-start;
  }

  .trip-end {
    align-items: flex-start;
  }
  .trip-start-end {
    gap: 1.4rem;
  }
}

@media only screen and (max-width: 450px) {
  .trip-content {
    flex-direction: column;
    justify-content: space-around;
    width: 20rem;
    height: 4.5rem;
  }

  .trip-list {
    gap: 20px;
  }
  .trip-logo-name img {
    display: none;
  }

  .trip-logo-name span {
    font-size: 11px;
    line-height: 16px;
  }

  .trip-start {
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
  }

  .trip-end {
    flex-direction: row;
    align-items: center;
    /* font-size: 9px; */
  }

  .span-two {
    font-size: 11px;
  }

  .trip-start-end {
    position: relative;
  }

  .up-coming-view {
    position: absolute;
    top: -30px;
    right: 47px;
  }

  .new-arrow-mobile {
    position: absolute;
    top: -20px;
    right: 10px;
  }
}

/* @media only screen and (max-width: 430px) {
  .trip-content {
    width: 19.25rem;
  }
} */
