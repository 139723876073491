.agreement-container {
  /* padding: 1rem; */
}
.agreement-container h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.section-agreement {
  margin: 1rem 0;
}

.agreement-row-wrap {
  width: 41rem;
  height: 70px;
  background: #f8f8f8;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.col-doc {
  font-weight: 400;
  font-size: 16px !important;
  line-height: 20px;
  color: #000000;
  border: 1px solid #f8f8f8;
}

.col-sign {
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  gap: 10px;
  width: 79px;
  height: 24px;
  /* background: rgba(231, 59, 21, 0.1); */
  /*  */
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  /* color: #e73b15; */
}

.agreement-docs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 5px;
  width: 148px;
  height: 30px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #f8f8f8;
}

.col-doc-view {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #969696;
}

/*  */
@media only screen and (max-width: 1200px) {
  .agreement-row-wrap {
    width: 39rem;
  }
}
@media only screen and (max-width: 450px) {
  .col-doc {
    font-size: 13px !important;
    line-height: 14px;
    margin-right: 0.5rem;
  }
  .col-sign {
    font-size: 9px;
  }
  .col-doc-view {
    font-size: 11px !important;
    line-height: 16px;
  }
  .agreement-docs {
    margin-left: 5px;
    width: 74px;
    text-align: center;
  }
  .agreement-container h2 {
    text-align: center;
    font-size: 15px;
  }
}
