*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.AppWrap {
  background-image: linear-gradient(rgba(0, 0, 0, 0.518), rgba(0, 0, 0, 0.518)),
    url("../public/img/tkeBG.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* min-width: 100vw; */
  object-fit: cover;
  min-height: 100vh;
  /* padding: 50rem; */
}

/* Media */
/* @media only screen and (max-width: 452px) {
  html {
    font-size: 10px;
  }
} */
