/* Date segment */
.dated {
  gap: 1rem;
}

.Bespoke-date {
  height: 50px;
  width: 130px;
  outline: none;
  border: none;
}
.Bespoke-date-1 {
  height: 50px;
  width: 130px;
  outline: none;
  border: none;
}

.Bespoke-date-1:focus,
.Bespoke-date:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

/* Date */
.one {
  height: 50px;
  border-radius: 0.375rem;
  background: #ffffff;
  padding: 0.4rem;
}
.two {
  width: 170px;
  height: 50px;
  border-radius: 0.375rem;
  background: #ffffff;
  padding: 0.4rem;
}

.Bespoke-date-1::-webkit-calendar-picker-indicator,
.Bespoke-date::-webkit-calendar-picker-indicator {
  display: none !important;
}

.calender {
  font-size: 2rem;
  /* align-self: stretch; */
  color: #828282;
}
.andTo {
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;

  color: #3e3e3e;
}

/* End Date segment */
/*  */

.form-control:focus {
  box-shadow: none;
  border: none;
  outline: none;
}
/*  */
/*  */

.bespoke-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3e3e3e;
}

.radio-flex {
  gap: 2rem;
}

.bespoke-radio {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
/*  */
/*  */
/*  */

.How-many {
  gap: 15px;
}

.adults {
  width: 127px;
  height: 50px;
  border: none;
  box-shadow: none;
  outline: none;
  background: #ffffff;
}

.children {
  width: 127px;
  height: 50px;
  border: none;
  box-shadow: none;
  outline: none;
  background: #ffffff;
}

.budget {
}

.budget-input {
  width: 222px;
  height: 50px;
  padding: 10px 21px;
  background: #ffffff;
  border: none;
  box-shadow: none;
  outline: none;
}

.budget-input:focus,
.children:focus,
.adults:focus,
.formArea-bespoke-input:focus {
  box-shadow: none;
  border: none;
  outline: none;
}
.budget-input::placeholder,
.children::placeholder,
.adults::placeholder,
.formArea-bespoke-input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #828282;
}

.formArea-bespoke {
}

.formArea-bespoke-input {
  /* width: 502px; */
  height: 94px;

  background: #ffffff;
}

.bespoke2-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

/*  */
/*  */

@media only screen and (max-width: 455px) {
  #space-btn {
    justify-content: end;
  }

  /*  */
  #check-b {
    margin-left: 1rem;
  }
  #check-b label {
    font-size: 15px;
  }
  /*  */
  #bes1,
  #bes2 {
    width: 90%;
    margin: 0.5rem auto;
  }
  .bespoke-label {
    margin-left: 0.9rem;
    font-size: 15px;
  }
  /* .howdy {
    margin-left: 1.5rem;
  } */

  .dated {
    flex-direction: column;
  }

  .Bespoke-date {
    width: 181px;
  }
  .two {
    margin: 0 auto;
  }

  .How-many {
    justify-content: center;
  }

  .bespoke2-label {
    margin-left: 1rem;
    font-size: 15px;
  }

  .budget-input {
    margin: 0 auto;
  }

  .formArea-bespoke-input {
    margin: 0 auto;
    /* width: 345px; */
  }

  .radio-flex {
    gap: 0rem;
    margin-left: 1.2rem;
    flex-direction: column;
  }
  /* input::placeholder {
    font-size: 11px;
  } */
}

@media only screen and (max-width: 350px) {
  .formArea-bespoke-input {
    margin: 0 auto;
    width: 100%;
  }
  .adults,
  .children {
    width: 109px;
    height: 45px;
  }
}
