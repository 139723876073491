.footer-container {
  border-top: 1px solid #3e3e3e;
  /* width: 1200px; */
  text-align: center;
  padding: 1rem;
  margin: 3rem 0 2rem 0;
}
.footer-container span {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 1rem 0;
}
