.agreement-container2 {
  /* padding: 1rem; */
}
.agreement-container2 h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.section-agreement2 {
  margin: 1rem 0;
}

.agreement-row-wrap2 {
  width: 41rem;
  height: 70px;
  background: #f8f8f8;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.col-doc2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  border: 1px solid #f8f8f8;
  margin-right: 4rem;
}

.col-sign2 {
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 79px;
  height: 24px;
  background: rgba(231, 59, 21, 0.1);
  /*  */
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #e73b15;
}

.agreement-invoice {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #000000;
  justify-content: center;
  align-items: center;
}

.agreement-invoice .payNow {
  cursor: pointer;
  /* width: 98px;
  height: 26px; */
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  background: #a78a48;
  display: flex;
  justify-self: center;
  align-items: center;
  padding: 0.2rem 0.4rem;
}

.agreement-invoice .invoiceV {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #af9250;
  margin-top: 0.5rem;
}

.agreement-docs2 {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 5px;
  width: 130px;
  height: 30px;

  background: #ffffff;
  border: 1px solid #f8f8f8;
}

.col-doc-view2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #969696;
  cursor: pointer;
}

/*  */

.col-amount {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.paid {
  background: rgba(31, 112, 3, 0.1);
  color: #1f7003;
  font-size: 13px;
  padding: 0.2rem 0.7rem;
}

/*  */
@media only screen and (max-width: 1200px) {
  .agreement-row-wrap2 {
    width: 39rem;
  }
}
@media only screen and (max-width: 450px) {
  .col-doc2 {
    font-size: 11px;
    line-height: 15px;
    margin-right: 2rem;
  }
  .col-sign2 {
    font-size: 9px;
    height: 15px;
    padding: 10px 2px 10px 3px;
    text-align: center;
  }
  .col-amount {
    font-size: 10px;
    line-height: 14px;
    margin: 0px 6px;
  }
  .col-doc-view2 {
    font-size: 10px;
    width: auto;
    height: 25px;
    line-height: 15px;
    /* padding: 0.2rem; */
  }
  .agreement-docs2 {
    padding: 8px;
    gap: 0px;
    width: auto;
    height: 45px;
    text-align: center;
  }
  .agreement-container2 h2 {
    font-size: 15px;
    text-align: center;
  }

  .agreement-invoice .payNow {
    font-size: 10px;
    padding: 0.1rem 0.2rem;
  }
  .agreement-invoice .invoiceV {
    margin-top: 0.2rem;
    font-size: 10px;
    margin-left: 1.3rem;
    line-height: 1.3em;
  }
}
