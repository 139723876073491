/* PLACES BANNER */
.places-banner {
  width: 62rem;
  height: 7.8rem;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  /* border-radius: 10px; */
}
.places-banner img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
/* END OF PLACES BANNER */

@media only screen and (max-width: 1200px) {
  .places-banner {
    width: 57.8rem;
  }
}

@media only screen and (max-width: 1000px) {
  .places-banner {
    width: 57.5rem;
  }
}
@media only screen and (max-width: 991px) {
  .places-banner {
    width: 45rem;
  }
}
@media only screen and (max-width: 767px) {
  .places-banner {
    width: 34rem;
  }
}

@media only screen and (max-width: 575px) {
  .places-banner {
    width: 32.4rem;
  }
}
@media only screen and (max-width: 545px) {
  .places-banner {
    width: 31.5rem;
  }
}
@media only screen and (max-width: 535px) {
  .places-banner {
    width: 31rem;
  }
}
@media only screen and (max-width: 520px) {
  .places-banner {
    width: 30rem;
  }
}
@media only screen and (max-width: 510px) {
  .places-banner {
    width: 29rem;
  }
}

@media only screen and (max-width: 480px) {
  .places-banner {
    width: 28rem;
  }
}
@media only screen and (max-width: 480px) {
  .places-banner {
    width: 27rem;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 450px) {
  .places-banner {
    width: 99%;
    height: 4.5rem;
  }
}
/* @media only screen and (max-width: 448px) {
  
}

@media only screen and (max-width: 420px) {
  .places-banner {
    width: 23rem;
    height: 4.9rem;
  }
}
@media only screen and (max-width: 410px) {
  .places-banner {
    width: 20.5rem;
    height: 4.9rem;
  }
} */
