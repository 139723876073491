.headers-container {
  height: 10.1rem;
  width: 12rem;
  margin-bottom: 5rem;
}

.header-logo {
  width: 100%;
  height: auto;
}

/*  */
@media only screen and (max-width: 900px) {
  .headers-container {
    height: 8rem;
    width: 8rem;
    margin-bottom: 3rem;
  }
}
