.user-home-container {
  width: 994px;
  /* min-height: 100vh; */
  color: white;
  padding: 0.3rem 0rem;
}

@media only screen and (max-width: 1200px) {
  .user-home-container {
    width: 920px;
  }
}

@media only screen and (max-width: 575px) {
  .user-home-container {
    width: 550px;
    padding: 1rem;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 550px) {
  .user-home-container {
    width: 545px;
  }
}
@media only screen and (max-width: 545px) {
  .user-home-container {
    width: 540px;
  }
}
@media only screen and (max-width: 535px) {
  .user-home-container {
    width: 530px;
  }
}
@media only screen and (max-width: 520px) {
  .user-home-container {
    width: 510px;
  }
}
@media only screen and (max-width: 510px) {
  .user-home-container {
    width: 500px;
  }
}
@media only screen and (max-width: 499px) {
  .user-home-container {
    width: 490px;
  }
}
@media only screen and (max-width: 480px) {
  /* .user-home-container {
    width: 476px;
  } */
  .user-home-container {
    width: 468px;
  }
}

@media only screen and (max-width: 467px) {
  .user-home-container {
    width: 460px;
  }
}
@media only screen and (max-width: 458px) {
  .user-home-container {
    width: 456px;
  }
}
@media only screen and (max-width: 450px) {
  .user-home-container {
    width: 95%;
  }
}
/* @media only screen and (max-width: 448px) {
  .user-home-container {
    width: 429px;
  }
}
@media only screen and (max-width: 430px) {
  .user-home-container {
    width: 425px;
  }
}
@media only screen and (max-width: 425px) {
  .user-home-container {
    width: 420px;
  }
}
@media only screen and (max-width: 420px) {
  .user-home-container {
    width: 415px;
  }
}
@media only screen and (max-width: 415px) {
  .user-home-container {
    width: 400px;
  }
}
@media only screen and (max-width: 410px) {
  .user-home-container {
    padding: 1rem 1rem;
    width: 370px;
  }
}
@media only screen and (max-width: 400px) {
} */

/* @media only screen and (max-width: 410px) {
  .user-home-container {
    width: 355px;
  }
} */
