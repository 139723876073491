.over-view-wrapper {
  /* padding: 1rem; */
}

.ov_header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.ov_header1 {
  font-weight: 300;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
}

.section-content {
  margin: 1.5rem 0;
}

.row-block {
  display: flex;
  gap: 40px;
  /* justify-content: space-between; */
}

.block-item {
  width: 9rem;
  height: 6rem;
  background: #f8f8f8;
  border-radius: 10px;
  color: #000000;

  /*  */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.block-item h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding-top: 0.9rem;
}
.block-item h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.block-item p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

/*  */

.block-item-1 {
  color: #000000;
  /*  */
  display: flex;
  align-items: center;
  position: relative;
  /*  */
}
.img-forward {
  width: 24px;
  height: 26px;
  background: rgba(167, 138, 72, 0.2);
  border-radius: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.3rem;
}

.block-item-1::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -20px;
  height: 0px;
  width: 26.0000000000001px;
  transform: rotateY(180deg);
  border: 1px solid #c1c1c1;
  border-radius: nullpx;
}
.block-item-1::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -20px;
  height: 0px;
  width: 26.0000000000001px;
  transform: rotateY(180deg);
  border: 1px solid #c1c1c1;
  border-radius: nullpx;
}

.row-divider {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /*  */
  margin: 1rem 4rem 1rem 0;
}
.row-divider1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /*  */
  margin: 1rem 0rem 1rem 4rem;
}
.divider-img {
  width: 26px;
  height: 26px;
  background: rgba(167, 138, 72, 0.2);
  border-radius: 100px;
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* margin: 0.3rem 0; */
}
.divider-img::before {
  content: "";
  position: absolute;
  height: 0px;
  width: 17px;
  left: 28px;
  top: 10px;
  border: 1px solid #c1c1c1;
  transform: rotate(-180deg);
}
.divider-img::after {
  content: "";
  position: absolute;
  height: 0px;
  width: 17px;
  left: -19px;
  bottom: 12px;
  border: 1px solid #c1c1c1;
  transform: rotate(-180deg);
}

/* SECTION IMAGE */

.section-image {
  margin: 2rem 0;
}

.section-img-wrap {
  width: 256px;
  /* height: 203px; */
  margin: 0.3rem;
  border-radius: 10px;
}

.section-img-wrap img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* section-decs */

.section-decs {
  margin: 1rem 0;
}

.section-decs-wrap {
}
.section-decs-wrap {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #000000;
}

@media only screen and (max-width: 450px) {
  .row-block {
    gap: 0.4rem;
  }
  .block-item {
    width: 5rem;
    height: 5rem;
  }
  .block-item h6 {
    font-size: 10px;
    line-height: 12px;
  }
  .block-item h4 {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }
  .block-item p {
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
  }
  .block-item-1::before {
    left: -5px;
    width: 14px;
  }
  .block-item-1::after {
    right: -5px;
    height: 0px;
    width: 14px;
  }
  .row-divider {
    margin: 1rem 1.5rem 1rem 0;
  }
  .row-divider1 {
    margin: 1rem 0 1rem 1.5rem;
  }
  .section-decs-wrap {
    font-weight: 400;
    font-size: 10px;
  }

  .ov_header {
    font-size: 14px;
  }
  .img-forward {
    width: 22px;
    height: 22px;
  }

  .section-decs .section-decs-wrap p {
    word-wrap: break-word;
    font-size: 0.8rem;
  }
}
