.contentHead {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5rem;
  text-align: center;
  color: #5f5f5f;
}

/*  */
@media only screen and (max-width: 455px) {
  .contentHead {
    font-size: 12px;
    line-height: 1rem;
    padding: 0.2rem 1rem;
  }
}
/* @media only screen and (max-width: 390px) {
  .contentHead {
    font-size: 12px;
  }
} */
