.title-questionaire {
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  color: #2d2e61;
  text-align: center;
}

/*  */
@media only screen and (max-width: 455px) {
  .title-questionaire {
    font-size: 14px;
    line-height: 25px;
  }
}
