.progressContainer {
}

.progressContainer span {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5f5f5f;
}

.progress {
  /* width: 502px; */
  height: 8px;
  background: #ffffff;
  border: 1px solid #b1b1b1;
  box-shadow: inset 0px 4px 4px rgba(182, 182, 182, 0.25);
  border-radius: 100px;
}

/*  */
@media only screen and (max-width: 455px) {
  .progressContainer {
    padding-right: 0px;
    padding-left: 0px;
    margin: 1rem;
  }
  .progressContainer span {
    font-weight: 500;
    font-size: 12px;
  }
  /* .progress {
    width: 400px;
    margin-left: 1rem;
  } */
}
/* @media only screen and (max-width: 390px) {
  .progressContainer {
    margin: 0 0.9rem;
  }
  .progress {
    width: 400px;
  }
} */
/* @media only screen and (max-width: 360px) {
  .progressContainer {
    margin: 0 1rem;
  }

  .progress {
    width: 320px;
  }
} */
