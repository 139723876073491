.trip-review-container {
  color: #000000;
}
.trip-review-container h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.section-trip-review {
}

.form-row {
}

/*  */
.upload-wrap {
  /*  */
  display: flex;
  align-items: center;
  gap: 2rem;
}

.upload-input {
  display: none;
}
.upload-label {
  width: 147px;
  height: 44px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: #f8f8f8;
  /*  */
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}
#imageName {
}

.upload-wrap span {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

/*  */

.text-area-wrap {
}
.text-area-wrap label {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.text-field {
  border: none;
  outline: none;
  width: 41.2rem;
  height: 15.3rem;
  background: #f8f8f8;
  /*  */
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  padding: 1rem 0 0 1rem;
  color: #959595;
}

/* BTN */
.btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 3rem 0.3rem 0 0;
}

.btn-container button {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  /* Background */
  border-radius: 0;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  outline: 1px solid #a78a48;
  outline-offset: 3px;
  width: 130px;
  height: 44px;
  border: none;
  /* TKE Gold */
  box-shadow: none;
  background: #a78a48;
  /*  */
}
.btn-container button:hover {
  background: #ae8947;
  outline: none;
  box-shadow: none;
  outline: 1px solid #a78a48;
  outline-offset: 5px;
}
.btn-container button:focus {
  background: #ae8947;
  outline: none;
  border-color: #ae8947;
  border: none;
  box-shadow: none;
}

.btn-container button:active {
  background: #ae8947;
  outline: none;
  border-color: #ae8947;
  border: none;
  box-shadow: none;
}

.instruction__div p {
  margin-bottom: 5px;
  line-height: 1;
}

/* End Of BTN */

/*  */
@media only screen and (max-width: 1200px) {
  .text-field {
    width: 37.2rem;
  }
}

@media only screen and (max-width: 767px) {
  .text-field {
    width: 30.2rem;
    padding: 1rem 1rem 0 1rem;
  }
}
@media only screen and (max-width: 530px) {
  .text-field {
    width: 27.2rem;
  }
}
@media only screen and (max-width: 520px) {
  .text-field {
    width: 99%;
  }
}
@media only screen and (max-width: 480px) {
  .text-field {
    width: 99%;
  }
}
@media only screen and (max-width: 455px) {
  .btn-container {
    justify-content: center;
    margin: 3rem 0.3rem 1rem 0;
  }
  .btn-container button {
    height: 35px;
  }
  .text-field {
    /* width: 24rem; */
  }
  /* .btn-container button {
    font-size: 15px;
    line-height: 16px;
    width: 100px;
  } */

  .btn-container button {
    font-size: 15px;
    line-height: 15px;
    width: 84px;
  }

  .trip-review-container h2 {
    text-align: center;
    font-size: 14px;
  }
  .text-area-wrap label {
    font-size: 12px;
  }
  .upload-label {
    font-size: 11px;
  }
  .upload-wrap span {
    font-size: 11px;
  }

  .instruction__div p {
    font-size: 0.8rem;
  }
}
