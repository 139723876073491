.user-trip-page-content {
  display: flex;
  gap: 20px;
}

/* SIDE BAR */

.trip-sidebar-list {
  width: 15rem;
  height: 28rem;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.sidebar-row {
  padding: 0px 15px;
  width: 13rem;
  height: 40px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  /* justify-content: center; */
  gap: 1.1rem;
  align-items: center;
}

.active-side {
  background: rgba(167, 138, 72, 0.2);
}

.sidebar-row .div-img {
  height: 18.75px;
  width: 18.75px;
  border-radius: 0px;
}
.sidebar-row .div-img .sidebar-img {
  height: auto;
}

.sidebar-row span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #a78a48;
}

/* END OF SIDE BAR */

/* TRIP PAGE */

.trip-page-details {
  width: 45rem;
  min-height: 35rem;
  background: #ffffff;
  border-radius: 10px;
  padding: 1rem;
}

.input-1 {
  border: 1px solid #a78a48;
  height: 40px;
  width: 280px;
}
.input-1::placeholder {
  padding: 0.4rem;
}

/* END TRIP PAGE */

/*  */
@media only screen and (max-width: 991px) {
  .user-trip-page-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .trip-sidebar-list {
    width: 100%;
    height: auto;
    flex-direction: row;
    gap: 1rem;
    /* padding: 3rem; */
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .trip-page-details {
    width: 33.8rem;
  }
}
@media only screen and (max-width: 575px) {
  /* .trip-sidebar-list {
    width: 59%;
  } */
  .trip-sidebar-list {
    width: 99%;
  }

  .trip-page-details {
    width: 32rem;
  }
}
@media only screen and (max-width: 540px) {
  .trip-page-details {
    width: 31rem;
  }
}
@media only screen and (max-width: 530px) {
  .trip-page-details {
    width: 30.5rem;
  }
}
@media only screen and (max-width: 520px) {
  .trip-page-details {
    width: 29.7rem;
  }
}
@media only screen and (max-width: 500px) {
  .trip-page-details {
    width: 28.5rem;
  }
}
@media only screen and (max-width: 500px) {
  .trip-page-details {
    width: 27.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .trip-page-details {
    width: 26.5rem;
  }
}
@media only screen and (max-width: 450px) {
  .user-trip-page-content {
    gap: 0px;
  }
  .trip-sidebar-list {
    width: 99%;
    flex-direction: row;
    min-height: 300px;
    background: #f5f5f5;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .sidebar-row {
    max-width: 103px;
    flex-direction: column;
    /* gap: 30px; */
    justify-content: center;
    align-items: center;
    background: #ffffff;
    /* border: 1px solid gray; */
    padding: 3rem;
  }

  /* .active-side {
    background: none;
  } */

  .sidebar-row .div-img {
    width: 15px;
    height: auto;
  }

  .sidebar-row span {
    font-size: 11px;
  }
  /*  */
  .trip-page-details {
    width: 99%;
    background: #f5f5f5;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  /* DISAPPEAR */
  #disappear {
    display: none;
  }
}

@media only screen and (max-width: 365px) {
  /* .trip-page-details {
    width: 22.7rem;
  } */
  .input-1 {
    height: 35px;
    width: 250px;
  }
}
@media only screen and (max-width: 320px) {
  .input-1 {
    height: 35px;
    width: 210px;
  }
}
