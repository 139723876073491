.travelMain {
  /* width: 500px; */
}

.firstLastName {
  position: relative;
  z-index: 100;
  opacity: 10;
  gap: 10px;
  /* background: #ffffff; */
}

.firstLastName-1 {
  z-index: 10;
  padding: 10px 21px;
  /* width: 236px; */
  height: 50px;
  /* background: #ffffff; */
  /* flex-basis: 50%; */
  opacity: 5;
}
.firstLastName-1::placeholder,
.firstLastName-2::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}

.firstLastName-2 {
  padding: 10px 21px;
  /* width: 236px; */
  height: 50px;
  background: #ffffff;
  /* flex-basis: 50%; */
  opacity: 2;
}

.firstLastName-1:focus,
.firstLastName-2:focus {
  border-color: none !important;
  outline: none;
  background: #ffffff;
  box-shadow: none !important;
  /* border: none; */
}

.email-container {
}

.email-container-input2,
.email-container-input {
  padding: 10px 21px;
  /* width: 502px; */
  height: 50px;
  background: #ffffff;
}
.email-container-input2::placeholder,
.email-container-input::placeholder,
number-container-2::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}

.number-container {
  position: relative;
  z-index: 100;
  opacity: 10;
  gap: 10px;
  /* background: #ffffff; */
}

.number-container-1 {
  padding: 10px 21px;
  /* width: 236px; */
  height: 50px;
  /* background: #ffffff; */
  /* flex-basis: 15%; */
}

.number-container-2 {
  padding: 10px 21px;
  width: 236px;
  height: 50px;
  background: #ffffff;
  width: 100%;
  /* flex-basis: 85%; */
  /* opacity: 2; */
}

/* .email-container-input2:focus, */
.email-container-input:focus,
.number-container-1:focus,
.number-container-2:focus {
  border-color: none !important;
  outline: none;
  background: #ffffff;
  box-shadow: none !important;
  /* border: none; */
}

/* BTN */
.btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 3rem 0.3rem 0 0;
}

.btn-container button {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  /* Background */
  border-radius: 0;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  outline: 1px solid #a78a48;
  outline-offset: 3px;
  width: 170px;
  height: 44px;
  border: none;
  /* TKE Gold */
  box-shadow: none;
  background: #a78a48;
  /*  */
}
.btn-container button:hover {
  background: #ae8947;
  outline: none;
  box-shadow: none;
  outline: 1px solid #a78a48;
  outline-offset: 5px;
}
.btn-container button:focus {
  background: #ae8947;
  outline: none;
  border-color: #ae8947;
  border: none;
  box-shadow: none;
}

.btn-container button:active {
  background: #ae8947;
  outline: none;
  border-color: #ae8947;
  border: none;
  box-shadow: none;
}

/* End Of BTN */

.email-container-input3 {
  padding: 10px 21px;
  height: 50px;
}

.email-container-input3:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.email-container-input3::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}

/*  */
@media only screen and (max-width: 575px) {
  .travelMain form {
    width: 90%;
    margin: 0.5rem auto;
  }

  .email-container-input2,
  .email-container-input {
    padding: 10px 10px;
    width: 100%;
    height: 45px;
    margin: 0 auto;
  }

  .TQCSS div {
    width: 100% !important;
  }
}

@media only screen and (max-width: 455px) {
  .number-container {
    flex-direction: column;
  }
  .btn-container {
    justify-content: center;
    margin: 3rem 0.3rem 1rem 0;
  }
  .btn-container button {
    height: 40px;
  }
  .firstLastName {
    flex-direction: column;
  }
  .number-container-2 {
    width: 100%;
  }

  label {
    font-size: 0.86rem !important;
    line-height: 0.95rem !important;
  }
  .email-container input::placeholder {
    font-size: 0.8rem;
  }
  .omo1 {
    color: green;
  }
}
@media only screen and (max-width: 350px) {
  .email-container-input2,
  .email-container-input {
    padding: 6px 6px;
    width: 100%;
  }
}
