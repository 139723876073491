.settings-container {
  width: 100%;
  min-height: 40rem;
  left: 223px;
  top: 185px;
  background: #ffffff;
  border-radius: 10px;
  padding: 2.5rem;
  color: #000000;
}

.back-dashboard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 1rem;
  width: 198px;
  height: 40px;
  background: #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.back-dashboard-icon {
  color: #000000;
}

.back-dashboard span {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.profile-wrap {
  margin: 2rem 0;
}
.profile-wrap h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 1rem 0;
}

.profile-row {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.profile-row img {
  width: 54px;
  height: 54px;
  background: #d9d9d9;
  border-radius: 100px;
}

.profile-row-div {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.profile-row-div span {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.profile-row-inner-div {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.profile-row-inner-div div {
  width: 19px;
  height: 19px;
  /* background: #a78a48; */
  border-radius: 15px;
}
.profile-row-inner-div img {
  width: 19px;
  height: 19px;
  /* background: #a78a48; */
  border-radius: 15px;
}

.profile-row-inner-div span {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  padding-top: 0.4rem;
}

/* SETUP FORM */

.profile-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin: 0.4rem 0;
}

.profile-input {
}

.profile-input:focus {
  outline: none;
  box-shadow: none;
}

/*  */
@media only screen and (max-width: 1200px) {
  .settings-container {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 450px) {
  .profile-row-div span {
    font-size: 12px;
    line-height: 15px;
  }
  .profile-row-inner-div div {
    font-size: 10px;
  }
  .profile-wrap h3 {
    font-size: 15px;
    line-height: 18px;
  }
  .profile-label {
    font-size: 11px;
    line-height: 18px;
  }
  .back-dashboard span {
    font-size: 14px;
  }
}
