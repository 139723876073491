/* USER JUMBOTRON */
.user-jumbotron {
  /* width: 100%; */
  background: #a78a48;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.jumbo-container {
  min-height: 131px;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.jumbo-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.jumbo-list-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
}

.jumbo-item {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.7);
}

.jumbo-item.Essential {
  padding-bottom: 5px;
}

.jumbo-item-2 {
  font-weight: 500;
  font-size: 16px;

  color: #ffffff;
}
.jumbo-item-2 p {
  margin-bottom: 5px;
  line-height: 1;
}
.jumbo-item-2 span {
  margin-bottom: 5px;
  line-height: 1;
}

.Essential img {
  width: 25px;
  padding-right: 0.4rem;
  /* background: rgba(255, 255, 255, 0.67); */
}

.jumbo-item-1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #ffffff;
}

/* END OF USER JUMBOTRON */

/*  */
@media only screen and (max-width: 767px) {
  .jumbo-item {
    font-size: 16px;
  }

  .jumbo-item-1 {
    font-size: 30px;
  }

  .jumbo-item-2 {
    font-size: 13px;
  }
}
@media only screen and (max-width: 575px) {
  .jumbo-container {
    /* height: 123px; */
    justify-content: space-around;
    align-items: center;
  }

  .jumbo-item {
    font-size: 13px;
  }

  .jumbo-item-1 {
    font-size: 26px;
  }

  .jumbo-item-2 {
    font-size: 13px;
  }
}
@media only screen and (max-width: 450px) {
  .jumbo-item {
    font-size: 10px;
    line-height: 15px;
  }

  .jumbo-item-1 {
    font-size: 20px;
  }

  .jumbo-item-2 {
    font-size: 10px;
  }

  .jumbo-container {
    min-height: 90px;
    gap: 12px;
  }
  .Essential img {
    width: 15px;
  }
  .jumbo-list {
    margin-left: 5px;
  }
}
