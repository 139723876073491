.agreement-container1 {
  /* padding: 1rem; */
}
.agreement-container1 h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.section-agreement1 {
  margin: 1rem 0;
}

.agreement-row-wrap1 {
  width: 41rem;
  height: 70px;
  background: #f8f8f8;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.col-doc1 {
  font-weight: 400;
  font-size: 16px !important;
  line-height: 20px;
  color: #000000;
  border: 1px solid #f8f8f8;
}

.agreement-docs1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 9px;
  width: 121px;
  height: 30px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #f8f8f8;
}

.col-doc-view1 {
  padding-right: 10px;
  color: #969696;
  /* background: #ffffff; */
}

/*  */

@media only screen and (max-width: 1200px) {
  .agreement-row-wrap1 {
    width: 39rem;
  }
  .col-doc1 {
    font-size: 12px;
    line-height: 16px;
  }
  .agreement-docs1 {
    width: 112px;
  }

  .col-doc-view1 {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1200px) {
  .agreement-container1 h2 {
    font-size: 15px;
    text-align: center;
  }
  .col-doc1 {
    font-size: 11px;
    line-height: 16px;
  }
  .agreement-docs1 {
    width: 112px;
  }

  .col-doc-view1 {
    font-size: 11px;
  }
}
@media only screen and (max-width: 450px) {
  .col-doc1 {
    font-size: 13px !important;
    line-height: 16px;
  }
  .agreement-docs1 {
    width: 80px;
  }
}
