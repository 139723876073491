.change-password {
  width: 60%;
  min-height: 20rem;
  left: 223px;
  top: 185px;
  background: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  color: #000000;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 7.2rem auto;
  text-align: center;
}

.form-ik {
  width: 80%;
  text-align: center;
  /* margin: 1rem auto; */
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formit-child {
  width: 90%;
  margin: 1rem 0;
  height: 50px;
  margin: 1rem 0px;
}

.passWordBTN {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border-radius: 0;
  color: #ffffff;
  padding: 10px;
  gap: 10px;
  outline: 1px solid #a78a48;
  outline-offset: 3px;
  width: 150px;
  height: 40px;
  border: none;
  box-shadow: none;
  background: #a78a48;
  margin: 1rem 0;
}

.passWordBTN:focus {
  background: #ae8947;
  outline: none;
  border-color: #ae8947;
  border: none;
  box-shadow: none;
}
.passWordBTN:active,
.passWordBTN:hover {
  color: #ffffff;
  background: #a78a48;
  width: 160px;
}

/* MEDIA QUERY */
@media only screen and (max-width: 675px) {
  .change-password {
    width: 100%;
  }

  .passWordBTN {
    font-size: 13px;
    line-height: 20px;
    width: 100px;
    height: 30px;
    padding: 5px 3px !important;
    margin: 0.3rem 0;
  }
}
@media only screen and (max-width: 415px) {
  .change-password h2 {
    font-size: 16px;
  }

  .formit-child {
    height: 45px;
  }
}
