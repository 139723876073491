/* USER HEADER */
.user-header {
  display: flex;
  justify-content: space-between;
}

.user-page-logo {
  height: 110px;
  width: 133px;
}

.trip-account-wrap {
  display: flex;
  align-items: center;
  gap: 30px;
}

/*  */
.trip-link {
  width: 229px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  position: relative;
}
.trip-link span {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  cursor: pointer;
}

.trip-link-options {
  position: absolute;
  width: 16rem;
  height: 90px;
  padding: 0.3rem 0.3rem;
  left: -5px;
  top: 4rem;
  background: #ffffff;
  border-radius: 5px;
  z-index: 10;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  text-align: start;
  vertical-align: middle;
  /*  */
  box-shadow: 0px 10px 21px -6px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 10px 21px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 21px -6px rgba(0, 0, 0, 0.75);
}

.trip-link-wrapper {
  /* justify-content: center; */
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
}

.trip-link-wrapper img {
  height: 24px;
  width: 24px;
  border-radius: 0px;
}

.trip-link-wrapper p {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-left: 0.3rem;
  margin-bottom: 0px;
  padding-bottom: 0.3rem;
  cursor: pointer;
}
.trip-link-wrapper p:hover {
  color: #4b4343;
  text-decoration: underline;
}

/*  */

/*  */
.user-settings {
  display: flex;
  align-items: center;
  gap: 10px;
}

.fame-logo {
  height: 28px;
  width: 28px;
  border-radius: 0px;
}

.user-option {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}

.user-option-list {
  position: absolute;
  width: 12rem;
  /* height: 8rem; */
  left: -4.3rem;
  top: 3rem;
  background: #ffffff;
  border-radius: 5px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*  */
  box-shadow: 0px 10px 21px -6px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 10px 21px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 21px -6px rgba(0, 0, 0, 0.75);
}

.user-option-wrapper {
  align-items: center;
  gap: 0.5rem;
  padding-left: 0.4rem;
  text-decoration: none;
}

.user-option-wrapper img {
  height: 20px;
  width: 19.5px;
  border-radius: 0px;
}

.user-option-wrapper p {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin: 0.2rem 0 0 0.2rem;
  padding-bottom: 0.5rem;
}

.user-option-list p:hover {
  color: #4b4343;
  text-decoration: underline;
}

.iconDown {
  margin-left: 0.5rem;
  font-size: 1.4rem;
  /* transform: rotate(90deg); */
}
.iconDown1 {
  font-size: 1.4rem;
}

/* END OF USER HEADER */

/*  */
@media only screen and (max-width: 767px) {
  .trip-account-wrap {
    gap: 10px;
  }

  .trip-link {
    width: 200px;
  }
  .trip-link span {
    font-size: 15px;
  }

  .user-option {
    font-size: 15px;
  }

  .user-page-logo {
    height: 100px;
    height: auto;
  }
}

@media only screen and (max-width: 575px) {
  .user-header {
    justify-content: unset;
    gap: 3rem;
    margin-top: 1rem !important;
  }

  .user-page-logo {
    height: auto;
    width: 70px;
  }

  .trip-link {
    width: 180px;
  }

  .trip-account-wrap {
    gap: 15px;
  }

  .user-header {
    /* justify-content: unset; */
    gap: 4rem;
    justify-content: space-between;
  }

  .trip-link span {
    font-size: 13px;
  }

  .user-option {
    font-size: 13.5px;
  }

  .trip-account-wrap {
    gap: 12px;
  }
}

@media only screen and (max-width: 505px) {
  .user-settings {
    gap: 6px;
  }
  .trip-link {
    width: 193px;
    height: 45px;
  }

  .trip-link span {
    display: flex;
    font-size: 13px;
    line-height: 17px;
    margin-right: 0.15rem !important;
    margin-left: 0.15rem !important;
  }
  .iconDown {
    margin-left: 0.2rem;
    font-size: 1.3rem;
  }

  .user-option {
    font-size: 12px;
    line-height: 15px;
  }
  .user-option span {
    display: flex;
    width: 65px;
  }

  .trip-account-wrap {
    gap: 14px;
  }

  .user-header {
    gap: 3rem;
  }

  .iconDown1 {
    /* margin-left: 0.1rem; */
    font-size: 2rem;
  }
}

@media only screen and (max-width: 458px) {
  .user-header {
    gap: 2.5rem;
  }
  .trip-account-wrap {
    gap: 10px;
  }

  .trip-link {
    width: 175px;
  }
}
@media only screen and (max-width: 450px) {
  .user-header {
    /* gap: 30px; */
    padding: 0px 12px 0px 0px;
  }

  .user-page-logo {
    width: 55px;
    height: auto;
  }

  .user-option-wrapper {
    gap: 0.2rem;
    padding-left: 0.3rem;
  }
  .user-option-wrapper img {
    height: auto;
    width: 15.5px;
  }
  .user-option-wrapper p {
    font-size: 11px;
  }

  .user-option-list {
    position: absolute;
    width: 8.5rem;
    height: auto;
    top: 2rem;
    left: -7rem;
  }
  .fame-logo {
    height: auto;
    width: 20px;
  }
  .user-settings {
    gap: 5px;
  }
  /*  */
  .iconDown1 {
    /* margin-left: 0.1rem; */
    font-size: 1rem;
  }
  .user-option span {
    width: 10%;
    position: relative;
  }

  .iconDown200 {
    position: absolute;
    top: -2px;
    left: 17px;
    font-size: 1.1rem !important;
    /* margin-left: 0.2rem; */
  }
  /*  */
  .trip-link-wrapper img {
    height: auto;
    width: 16px;
  }
  .trip-link-wrapper p {
    font-size: 11px;
  }
  .trip-link-options {
    width: 11rem;
    top: 2rem;
  }
  .user-option {
    font-size: 10px;
  }

  .trip-link {
    width: 140px;
    height: 30px;
  }

  .mx-1 {
    width: 10px;
  }
  .trip-link span {
    font-size: 10px;
  }
  .iconDown {
    /* margin-left: 0.2rem; */
    font-size: 1rem;
  }
  /*  */
}
